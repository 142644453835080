<template>
  <!-- Header -->
  <header v-if="showHeader">
    <AppHeader
      title="Develop"
      :userDisplayName="loggedInUser?.memberName || ''"
      :navOptions="navOptions"
      :selectedNavLink="activeLinkFirstSegment"
      :appMenuItems="appMenuItems"
      :userMenuItems="userMenuItems"
      @nav="goToLink"
      @logout="logout"
      @userMenuClick="handleUserMenuClick($event)"
      @goToApp="goToApp"
    />
  </header>

  <!-- Main section (router outlet) -->
  <main>
    <NotificationSystem ref="notify" />
    <Loader />
    <ConfirmDialog />
    <router-view />
    <SessionExpirationDialog
      v-model="showSessionExpirationDialog"
      :countdown-seconds="expirationCountdownSeconds"
      @logout="logout"
      @refresh="onRefreshClicked"
    ></SessionExpirationDialog>
  </main>
</template>

<script setup>
import { ref, computed, provide, onMounted, onUnmounted } from 'vue';
import { AppHeader, NotificationSystem, SessionExpirationDialog } from 'vue-components';
import { useAuthStore, useDraftsStore, useConfigStore } from '@/stores';
import { useRoute, useRouter } from 'vue-router';
import { Loader, ConfirmDialog } from '@/components';
import { SESSION_EXPIRATION_MESSAGE_METHOD, SESSION_EXPIRATION_COUNTDOWN_IN_MS } from '@/utils/cross-domain-helpers';

const defaultNavOptions = [
  { displayName: 'Dashboard', route: { path: '/dashboard', name: 'ManagerDash' } },
  { displayName: 'People', route: { path: '/people', name: 'People' } },
  { displayName: 'Insights', route: { path: '/insights', name: 'Insights' } }
];
let permittedNavOptions = ref([]);

let notify = ref(null);

provide('notify', notify);

const authStore = useAuthStore();
const draftsStore = useDraftsStore();
const configStore = useConfigStore();
const router = useRouter();
const route = useRoute();
const isUserAdmin = ref(false);
const showSessionExpirationDialog = ref(false);
const expirationCountdownSeconds = ref(SESSION_EXPIRATION_COUNTDOWN_IN_MS / 1000);

const loggedInUser = ref({});

// Computed Properties
const appMenuItems = computed(() => {
  const menuItems = [];

  if (configStore.config && configStore.config.externalLinks) {
    for (const link of Object.values(configStore.config.externalLinks)) {
      if ((isUserAdmin.value && link.admin) || !link.admin) {
        menuItems.push({
          displayText: link.displayName,
          event: link.url
        });
      }
    }
  }

  return menuItems;
});

const userMenuItems = computed(() => {
  const count = draftsStore.drafts.length ? ` (${draftsStore.drafts.length})` : '';
  return [{ id: 'mydrafts', displayText: `My Drafts${count}`, event: 'goToMyDrafts', icon: 'drafts' }];
});
const showHeader = computed(() => route.name !== 'Login');
const activeLinkFirstSegment = computed(() => `/${route.path.split('/')[1]}`);
const navOptions = computed(() => [...defaultNavOptions, ...permittedNavOptions.value]);

const goToLink = route => {
  router.push({ name: route.name });
};

const handleUserMenuClick = event => {
  const handlers = {
    goToMyDrafts: () => {
      router.push({ name: 'MyDrafts' });
    }
  };

  handlers[event]();
};

const goToApp = link => {
  location.assign(link);
};

/**
 * Trigger a session logout if user requested so in Session Expiration Dialog
 */
const logout = () => {
  authStore.logout();
  showSessionExpirationDialog.value = false;
};

/**
 * Refresh tokens if user requested so in Session Expiration Dialog
 */
const onRefreshClicked = () => {
  authStore.getTokens(true);
  showSessionExpirationDialog.value = false;
};

const onMessageReceived = e => {
  let data = e.data;
  if (data.method == SESSION_EXPIRATION_MESSAGE_METHOD) {
    expirationCountdownSeconds.value = data.value / 1000;
    showSessionExpirationDialog.value = true;
  }
};

onMounted(async () => {
  authStore.checkPermission('Coaching', 'admin').then(canAdmin => {
    if (canAdmin) {
      permittedNavOptions.value = [{ displayName: 'Admin', route: { path: '/admin', name: 'Admin' } }];
    }
  });

  authStore.getLoggedInUser().then(user => {
    loggedInUser.value = user;
  });

  window.addEventListener('message', onMessageReceived);

  try {
    isUserAdmin.value = await authStore.checkPermission('Admin Tool', 'read');
    await configStore.getConfigData();
  } catch (e) {
    notify.value?.error(e);
  }
});

onUnmounted(() => {
  window.removeEventListener('message', onMessageReceived);
});

// Expose for testing
defineExpose({ activeLinkFirstSegment, userMenuItems, handleUserMenuClick, showHeader });
</script>

<style lang="scss">
main {
  position: relative;
}
</style>
